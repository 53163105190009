/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

import { Formik } from 'formik';
import { Input, Button } from '@chakra-ui/react';

export function DashboardMenu({
	history,
	guildId,
	user,
	config,
}) {
	console.log(user);
	console.log(config);

	const [ prefix, setPrefix ] = React.useState('f;');

	return (
		<Formik
			initialValues={{ prefix }}
			onSubmit={(values) => {
				console.log(values);
			}}>
			{
				(props) => (
					<form onSubmit={props.handleSubmit}>
						<Input type="text" name="prefix" onChange={props.handleSubmit} defaultValue={prefix} />
						<Button type="submit daddy" colorScheme="blue">Update Prefix</Button>

					</form>
				)
			}

		</Formik>
	);
}