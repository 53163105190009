/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';

import { getGuildConfig, getUserDetails } from '../../utils/api';
import { DashboardMenu } from '../../components';

export function DashboardPage({
	history,
	match,
}) {
	const [ user, setUser ] = React.useState(null);
	const [ loading, setLoading ] = React.useState(true);
	const [config, setConfig] = React.useState({});

	React.useEffect(() => {
		getUserDetails()
			.then(({ data }) => {
				console.log(data);
				setUser(data);
				return getGuildConfig(match.params.id);
			}).then(({ data }) => {
				console.log(data);
				setConfig(data);
				setLoading(false);
			})
			.catch(() => {
				history.push('/');
				setLoading(false);
			});
	}, []);

	return (!loading && (
		<div>
			<h1>
                Dashboard Page
			</h1>
			<DashboardMenu user={user} config={config}/>
		</div>
	)
	);
}