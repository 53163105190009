/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { MenuComponents } from '../../components';
import { getGuilds, getUserDetails } from '../../utils/api';

export function MenuPage({
	history,
}) {

	const [ user, setUser ] = React.useState(null);
	const [ loading, setLoading ] = React.useState(true);
	const [ guilds, setGuilds] = React.useState([]);

	React.useEffect(() => {
		getUserDetails()
			.then(({ data }) => {
				console.log(data);
				setUser(data);
				setLoading(false);
				return getGuilds();
			}).then(({ data }) => {
				console.log(data);
				setGuilds(data);
			}).catch(() => {
				history.push('/');
				setLoading(false);
			});
	}, []);

	return !loading && (
		<div>
			<h1>
            Main Page
			</h1>
			<MenuComponents guilds={guilds}/>
		</div>
	);
}