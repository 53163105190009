/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

export function MenuComponents({
	guilds,
}) {

	return (
		<div>
			{guilds.map((guild) => (
				<div>
					<li>{guild.name}</li>
					<Link to={`/dashboard/${guild.id}`}>View on Dashboard</Link>
				</div>
			))}
		</div>
	);
}