/* eslint-disable react/react-in-jsx-scope */
import './App.css';
import { Switch, Route } from 'react-router-dom';
import { DashboardPage, LandingPage, MenuPage } from './Pages';

function App() {
	return (
		<Switch>
			<Route path="/" exact={ true } component={LandingPage} />
			<Route path="/menu" exact={ true } component={MenuPage} />
			<Route parh="/dashboard/:id" exact={ true } component={DashboardPage} />
		</Switch>
	);
}

export default App;
